/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'

const Preloader = () => {
  return (
    <div css={css`width:100%;height:100vh;background-color:red`}>
      xc
    </div>
  )
}

export default Preloader
